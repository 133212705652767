import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import GatsbyImage from 'gatsby-image';
import { graphql } from 'gatsby';
import { rem, rgba } from 'polished';

import { P, Headline } from './Typography';
import Icon from './Icon';

import { fluidImageType } from '../helpers/prop-types';
import { colors, fontSizes } from '../helpers/variables';
import { pseudoStateClasses, mq } from '../helpers/stylehelpers';

/** Wrapper um die Komponente herum */
const Wrapper = styled.div`
    cursor: pointer;
    ${mq.smallOnly`
        margin-bottom: 1.75em;
        &:last-child {
            margin-bottom: 0;
        }
    `};

    &:hover,
    &:active,
    &:focus {
        strong {
            text-decoration: underline;
        }
    }
`;

/** Wrapper um Text und Bild */
const ImageWrapper = styled.div`
    ${({ boxType }) =>
        boxType === 'news' &&
        css`
            margin-bottom: ${rem(10)};
            ${mq.large`margin-bottom: ${rem(40)};`}
        `}
`;

const ContentWrapper = styled.div`
    ${({ boxType }) =>
        boxType === 'reference' &&
        css`
            &:before {
                content: '';
                display: block;
                width: ${rem(65)};
                height: ${rem(2)};
                background-color: ${colors.primary};
                margin-bottom: 1rem;
            }
            padding: 1rem;
            border: 1px solid ${colors.gray300};
        `}
`;

/** Bild */
const Img = styled(GatsbyImage)`
    box-shadow: 0px 0px 4px ${rgba(colors.black, 0.15)};
`;

/** Angepasste Headline */
const Title = styled(Headline)`
    ${fontSizes.h5}
    background-color: ${colors.primary};
    color: ${colors.white};
    padding: 0.55em;

    ${mq.large`
        ${fontSizes.h4}
        display: flex;
        align-items: center;
        color: ${colors.black};
        justify-content: center;
        background-color: ${colors.gray100};
        position: absolute;
        transform: translate(15%, -85%);
        height: ${rem(150)};
        width: ${rem(150)};

        span {
            position: relative;
            width: 100%;
            &:after, &:before {
                position: absolute;
                left: 0;
                content: '';
                height: 2px;
                width: 80%;
                background-color: ${colors.primary};
            }

            &:before {
                top: ${rem(-10)};
            }

            &:after {
                bottom: ${rem(-8)};
            }
        }

    `}


`;

/** Teasertext */
const Teaser = styled(P)`
    margin-bottom: 0.5em;
    color: ${colors.black};
`;

/** Weiterlesen Link */
const DetailLink = styled.strong`
    color: ${colors.primary};
    span {
        margin-left: 0.45em;
    }
    ${pseudoStateClasses`
        text-decoration: underline;
    `}
`;

/**
 * Erzeugt eine Newsbox
 * @param {string} props.title Titel der Box
 * @param {string} props.teaser Teasertext
 * @param {fluidImageType} props.image Vorschaubild
 */
const NewsOrReferenceBox = ({ title, teaser, image, onClick, boxType }) => {
    return (
        <>
            <Wrapper onClick={onClick}>
                <ImageWrapper boxType={boxType}>
                    <Img fluid={image.childImageSharp.fluid} />
                    {boxType === 'news' && (
                        <Title level="h3">
                            <span>{title}</span>
                        </Title>
                    )}
                </ImageWrapper>
                <ContentWrapper boxType={boxType}>
                    {boxType === 'reference' && <Headline level="h3">{title}</Headline>}
                    <Teaser>{teaser}</Teaser>
                    <DetailLink>
                        <Icon type="caretRight" />
                        <span>Weiterlesen</span>
                    </DetailLink>
                </ContentWrapper>
            </Wrapper>
        </>
    );
};

NewsOrReferenceBox.propTypes = {
    title: PropTypes.string.isRequired,
    teaser: PropTypes.string.isRequired,
    image: fluidImageType.isRequired,
    onClick: PropTypes.func,
    boxType: PropTypes.string.isRequired,
};

NewsOrReferenceBox.defaultProps = {
    onClick: null,
};

export default NewsOrReferenceBox;

/**
 * GraphQL Fragment für GatsbyImage
 * @type {GraphQL}
 */
// eslint-disable-next-line no-undef
export const query = graphql`
    fragment NewsOrReferenceBoxFluid on File {
        childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`;
