import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import ListAndPanel from '../templates/ListAndPanel';

const NewsPage = ({ data }) => (
    <ListAndPanel
        data={data}
        stageHeadline="News"
        contentHeadline="Bei uns gibt es immer etwas Neues."
        boxType="news"
    />
);
NewsPage.propTypes = {
    data: PropTypes.shape({}).isRequired,
};

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        stageImage: file(relativePath: { glob: "news/stage-image.jpg" }) {
            ...StageFluid
        }
        boxes: allPrismicNews(sort: { fields: first_publication_date }) {
            edges {
                node {
                    id
                    uid
                    data {
                        headline {
                            text
                        }
                        teaser {
                            text
                        }
                        teaserimage {
                            localFile {
                                ...NewsOrReferenceBoxFluid
                            }
                        }
                        detailimages {
                            detailimage {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 860, quality: 80) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                        detailtext {
                            html
                        }
                    }
                }
            }
        }
    }
`;

export default NewsPage;
