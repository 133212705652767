import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rgba } from 'polished';
import Modal from 'react-modal';
import Img from 'gatsby-image';

import { colors, fontSizes } from '../helpers/variables';
import { mq } from '../helpers/stylehelpers';

import Container from './Container';
import Toggle from './Toggle';
import Slider from './Slider';
import Button from './Button';
import { Headline } from './Typography';
import Icon from './Icon';

import { setUrlParam } from '../helpers/utils';

Modal.setAppElement(process.env.GATSBY_BUILD_STAGE ? '#___gatsby' : '#root');

/** Custom-Styles für React-Modal */
const customStyles = {
    overlay: {
        zIndex: 1002,
        backgroundColor: rgba(colors.black, 0.65),
    },
    content: {
        border: 0,
        borderRadius: 0,
        padding: '1em 1.25em',
        boxShadow: `0 0 5px 5px ${rgba(colors.black, 0.25)}`,

        left: '5%',
        top: 0,
        right: 0,
        bottom: 0,
    },
};

/** Toggler zum schließen */
const StyledToggle = styled(Toggle)`
    position: absolute;
    right: 0;
    top: 0;
`;

/* Gewrappter Container */
const Wrapper = styled(Container)`
    position: relative;
    padding: 3em 0 !important;
    ${mq.large`
        padding: 3em 1em !important;
    `};
`;

/* Gewrappter Slider */
const StyledSlider = styled(Slider)`
    max-width: 100%;
`;

const Pagination = styled.div`
    border-bottom: 1px solid ${colors.gray300};
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 2rem;
    padding: 0.5rem 0;
`;

const PaginationButton = styled.button`
    ${fontSizes.s};
    background: none;
    border: none;
    color: ${colors.black};
    cursor: pointer;
    margin: 0;
    padding: 0;
    transition: color 0.25s;

    &:disabled {
        pointer-events: none;
        opacity: 0.25;
    }

    &:hover,
    &:active,
    &:focus {
        color: ${colors.primary};
    }
`;

const PaginationIcon = styled(Icon)`
    ${({ left }) => left && `margin-right: 0.5rem`};
    ${({ right }) => right && `margin-left: 0.5rem`};
`;

/**
 * Zeigt ein Modal, mit den Referenzen oder News (nur für diese Daten geeignet)
 * @param {booean} isOpen Flag, ob Modal angezeigt wird oder nicht
 * @param {func} onRequestClose Methode, die das Modal schließt
 * @param {object} jobDetail Details für den Job
 */
const Panel = ({ isOpen, onRequestClose, all, index }) => {
    const [currentIndex, setCurrentIndex] = useState(index);
    const [details, setDetails] = useState(all[index].node.data);

    const pageTo = offset => {
        const newIndex = currentIndex + offset;
        setDetails(all[newIndex].node.data);
        setCurrentIndex(newIndex);
        setUrlParam('uid', all[newIndex].node.uid);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={customStyles}
            closeTimeoutMS={350}
        >
            <Wrapper size="l">
                <StyledToggle icon="close" onClick={onRequestClose} />
                {details && (
                    <>
                        <Container>
                            {all.length > 1 && (
                                <Pagination>
                                    <PaginationButton
                                        type="button"
                                        onClick={() => pageTo(-1)}
                                        disabled={currentIndex - 1 < 0}
                                    >
                                        <PaginationIcon type="caretLeft" left />
                                        Vorherige
                                    </PaginationButton>
                                    <PaginationButton
                                        type="button"
                                        onClick={() => pageTo(1)}
                                        disabled={currentIndex + 1 > all.length - 1}
                                    >
                                        Nächste
                                        <PaginationIcon type="caretRight" right />
                                    </PaginationButton>
                                </Pagination>
                            )}

                            <Headline level="h3" gap="l">
                                {details.headline.text}
                            </Headline>
                            {/* eslint-disable react/no-danger */}
                            <StyledSlider hideCounter>
                                {details.detailimages.map(({ detailimage }) => (
                                    <Img
                                        fluid={detailimage.localFile.childImageSharp.fluid}
                                        key={detailimage.localFile.childImageSharp.fluid.src}
                                    />
                                ))}
                            </StyledSlider>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: details.detailtext.html,
                                }}
                            />
                            {/* eslint-enable react/no-danger */}
                            <Button to="/kontakt">Interesse geweckt?</Button>
                        </Container>
                    </>
                )}
            </Wrapper>
        </Modal>
    );
};

Panel.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    all: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    index: PropTypes.number.isRequired,
    onRequestClose: PropTypes.func.isRequired,
};

export default Panel;
