import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Stage from '../components/Stage';
import Section from '../components/Section';
import NewsOrReferenceBox from '../components/NewsOrReferenceBox';
import Container from '../components/Container';
import Button from '../components/Button';
import Panel from '../components/Panel';
import { Headline, Highlight } from '../components/Typography';
import { fluidImageType } from '../helpers/prop-types';

import { getUrlParam, setUrlParam, removeAllUrlParams } from '../helpers/utils';

const ListAndPanel = ({ data, stageHeadline, contentHeadline, boxType }) => {
    const { stageImage } = data;
    const allItems = data.boxes.edges.filter(item => item.node.uid !== 'placeholder');
    const limit = 9;
    const [currentItems, setAllItems] = useState(allItems.slice(0, limit));
    const [index, setIndex] = useState(null);

    // Öffnet das Panel
    const openPanel = (newIndex, uid) => {
        setIndex(newIndex);
        setUrlParam('uid', uid);
        document.getElementsByTagName('body')[0].classList.add('modal-active');
    };

    // Schließt das Panel
    const closePanel = () => {
        setIndex(null);
        removeAllUrlParams();
        document.getElementsByTagName('body')[0].classList.remove('modal-active');
    };

    // Url Param checken (nur einmal bei Mount)
    useEffect(() => {
        const uid = getUrlParam('uid');
        const indexByParam = currentItems.findIndex(item => item.node.uid === uid);
        if (indexByParam !== -1) {
            setIndex(indexByParam);
        }
    }, []);

    // Boxen bauen
    const boxes = currentItems.map(({ node: { data: boxData, id, uid } }, itemIndex) => {
        return (
            <NewsOrReferenceBox
                key={id}
                image={boxData.teaserimage.localFile}
                title={boxData.headline.text}
                teaser={boxData.teaser.text}
                onClick={() => openPanel(itemIndex, uid)}
                boxType={boxType}
            />
        );
    });

    return (
        <>
            {index != null && (
                <Panel
                    isOpen={index != null}
                    onRequestClose={closePanel}
                    all={currentItems}
                    index={index}
                />
            )}

            <Layout title="News">
                <Stage image={stageImage.childImageSharp} title={stageHeadline} />
                <Section>
                    {boxes && boxes.length > 0 ? (
                        <>
                            <Headline level="h2" textAlign="center" gap="xl">
                                {contentHeadline}
                            </Headline>
                            <Container size="l" grid cols="3" gap="xxxl">
                                {boxes}
                            </Container>
                            {currentItems.length < allItems.length && (
                                <Container display="flex" justify="center">
                                    <Button onClick={() => setAllItems(allItems)}>
                                        Alle Neuigkeiten laden
                                    </Button>
                                </Container>
                            )}
                        </>
                    ) : (
                        <Highlight textAlign="center" display="block">
                            Momentan sind keine Daten vorhanden. Schauen Sie demnächst wieder
                            vorbei.
                        </Highlight>
                    )}
                </Section>
            </Layout>
        </>
    );
};

ListAndPanel.propTypes = {
    stageHeadline: PropTypes.string.isRequired,
    contentHeadline: PropTypes.string.isRequired,
    boxType: PropTypes.string.isRequired,
    data: PropTypes.shape({
        stageImage: fluidImageType,
        boxes: PropTypes.object,
    }).isRequired,
};

export default ListAndPanel;
